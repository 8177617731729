import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _createSvgIcon2 from "../../createSvgIcon";
import * as _Info3 from "@rsuite/icon-font/lib/legacy/Info";

var _Info2 = "default" in _Info3 ? _Info3.default : _Info3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(_createSvgIcon2);

var _Info = _interopRequireDefault(_Info2); // Generated by script, don't edit it please.


var Info = (0, _createSvgIcon["default"])({
  as: _Info["default"],
  ariaLabel: "info",
  category: "legacy",
  displayName: "Info"
});
var _default = Info;
exports["default"] = _default;
exports = exports.default;
export default exports;